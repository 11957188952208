.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* position: absolute;
  z-index: 0; */
  /* overflow: hidden; */
  /* background-attachment: fixed;
  background-position: center; 
  background-image: url("../public/wedding-rings-152336_1280.png");  */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  /* background-color: #adc4f3; */
  /* background-color: whitesmoke; */
  background-color: #F9EFE5; /* Tusk (mathcing invitation) */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p {
  color: black;
}

h1 {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}
